import {QueryHookOptions, gql, useLazyQuery} from "@apollo/client";
import {API_CLIENT} from "@services/graphql/client";

import {useAuthedMonolithQuery} from "../../../../hooks/useAuthedMonolithQuery";

type PatientFragment = {
  id: string;
  consumerId: string;
};

type DefaultPatientQuery = {
  patient: PatientFragment;
};

export const GET_PATIENT = gql`
  query GetPatient {
    patient
      @rest(
        type: "Patient"
        endpoint: "monolith"
        path: "/hib/patients/default"
        bodySerializer: "AUTH_HEADER"
      ) {
      id
      consumerId
    }
  }
`;

export const useDefaultPatientQuery = (options: QueryHookOptions<DefaultPatientQuery> = {}) => {
  return useAuthedMonolithQuery<DefaultPatientQuery>(GET_PATIENT, options);
};

export const useDefaultPatientLazyQuery = ({
  context,
  ...options
}: QueryHookOptions<DefaultPatientQuery> = {}) => {
  return useLazyQuery<DefaultPatientQuery>(GET_PATIENT, {
    ...options,
    context: {
      clientName: API_CLIENT,
      ...context,
    },
  });
};
