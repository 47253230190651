import {ParsedUrlQueryInput} from "querystring";

import {CarbonEnvName, CarbonEnvs} from "src/constants/urls";

const dotCom = (str: string) => `${str}.com`;

/**
 *
 * @param host value of window.location.host, URL.host, req.headers.host
 * @returns For internal environments, returns a value of the enum `InternalEnv`, else returns the
 */
export const getCarbonHost = (
  host: CarbonEnvName | string,
): (typeof CarbonEnvs)[CarbonEnvName] | null => {
  try {
    const [first, second] = host.split(".");
    const hosts = Object.values(CarbonEnvs);
    const maybeHost = hosts.find(
      h =>
        (h.subdomain === first && h.domain === dotCom(second)) ||
        h.domain === first ||
        (h.domain === dotCom(first) && !h.subdomain),
    );

    return maybeHost || null;
  } catch {
    return null;
  }
};

export const getRootUrlFromCarbonHost = (
  components: ReturnType<typeof getCarbonHost>,
): string | null => {
  if (!components) return null;

  const {subdomain, domain, protocol} = components;

  return subdomain ? `${protocol}://${subdomain}.${domain}` : `${protocol}://${domain}`;
};

const isCarbonEnv = (host: string): boolean => Boolean(getCarbonHost(host));

export const parseSearchParams = (searchParams: URLSearchParams) =>
  Array.from(searchParams.entries()).reduce<ParsedUrlQueryInput>(
    (acc, [key, value]) => ({...acc, [key]: value}),
    {},
  );

/**
 * When using this function, consider that it can throw errors if given a bad input to the URL constructor.
 */
export const parseHrefForLink = (
  href: string,
): {pathname: string; query: ParsedUrlQueryInput} | string => {
  const url = new URL(href);
  return isCarbonEnv(url.host)
    ? {
        pathname: url.pathname,
        query: parseSearchParams(url.searchParams),
      }
    : url.toString();
};
