import isbot from "isbot";

import {CarbonEnvName, CarbonEnvs} from "../../constants/urls";
import {isCI, isVercelPreview} from "../env";

const {dev, prod, alpha} = CarbonEnvs;

const SERVICE = "app-analytics";
const BASE = "carbonhealth.com";
const ENDPOINT = "/v1/events";

export const analyticsUrls: Record<CarbonEnvName, string> = {
  [CarbonEnvName.DEV]: `${dev.protocol}://localhost:8080${ENDPOINT}`,
  [CarbonEnvName.PROD]: `${prod.protocol}://${SERVICE}.${BASE}${ENDPOINT}`,
  [CarbonEnvName.ALPHA]: `${alpha.protocol}://${SERVICE}.staging.${BASE}${ENDPOINT}`,
};

export const getAnalyticsEndpoint = () =>
  !isCI && !isVercelPreview && !isbot(navigator.userAgent) && process.env.NODE_ENV === "production"
    ? analyticsUrls.prod
    : analyticsUrls.alpha;
