import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from "@apollo/client";
import {RestLink} from "apollo-link-rest";

import {getApiUrl, getGraphQLUrl} from "../../components/_common/_constants";
import {getToken} from "../../utils/browser-storage/tokens";

/**
 * Apollo client for the project.
 *
 * Much of our data still lives in the monolith, which does _not_ support graphql.
 * This client allows components to pull from either the graphql server or the monolith.
 *
 * Callers can choose which data source to pull from using the context. For example:
 *
 * useQuery(YOUR_QUERY, {
 *   context: { clientName: GRAPHQL_CLIENT }
 * });
 *
 */
export const API_CLIENT = "rest-api-client";
export const GRAPHQL_CLIENT = "graphql-client";

/**
 * Docs: https://www.apollographql.com/docs/react/api/link/apollo-link-rest/
 */
const restLink = new RestLink({
  uri: getApiUrl(),
  customFetch: async function authedFetch(input: RequestInfo | URL, init: RequestInit = {}) {
    const request = new Request(input, init);
    const token = getToken();
    if (token) {
      request.headers.set("authorization", `Bearer ${token}`);
    }
    request.headers.set("carbonapptype", "patient");
    request.headers.set("carbonplatformtype", "web");
    return fetch(request);
  },
});

const graphqlLink = new HttpLink({
  fetch: async function authedFetch(input: RequestInfo | URL, init: RequestInit = {}) {
    const request = new Request(input, init);
    const token = getToken();
    if (token) {
      request.headers.set("authorization", `Bearer ${token}`);
    }
    request.headers.set("carbonapptype", "patient");
    request.headers.set("carbonplatformtype", "web");
    request.headers.set("content-type", "application/json");
    return fetch(request);
  },
  uri: getGraphQLUrl(),
});

const link = ApolloLink.split(
  operation => operation.getContext().clientName === API_CLIENT,
  restLink,
  graphqlLink,
);

/**
 * This client should be used for client-side code.
 *
 * Docs: https://www.apollographql.com/docs/react/api/core/ApolloClient
 */
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  connectToDevTools: true,
  assumeImmutableResults: true,
});

export default client;

/** This client should be used for server-side code (e.g. getServerSideProps()). */
export const ssrClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  ssrMode: true,
  assumeImmutableResults: true,
});
