import {useEffect} from "react";
import {setIsKeyboardUser} from "src/utils/browser-storage/isKeyboardUser";

export const useSetKeyboardUser = (): void => {
  useEffect(() => {
    const checkForKeyboardUser = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        setIsKeyboardUser(true);
        window.removeEventListener("keydown", checkForKeyboardUser);
      }
    };

    window.addEventListener("keydown", checkForKeyboardUser);
    return () => window.removeEventListener("keydown", checkForKeyboardUser);
  }, []);
};
