import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";
import {API_CLIENT} from "@services/graphql/client";

import {useToken} from "../../utils/browser-storage/tokens";

/**
 * Wrapper around `useQuery` for monolith rest link queries that require an auth token.
 * It will refetch the query when the token changes and skip the query when there is no token.
 */
export const useAuthedMonolithQuery = <T, V extends OperationVariables = OperationVariables>(
  document: DocumentNode | TypedDocumentNode<T, V>,
  {skip = false, context, ...options}: QueryHookOptions<T, V>,
) => {
  const token = useToken();
  const query = useQuery<T, V>(document, {
    ...options,
    context: {
      clientName: API_CLIENT,
      ...context,
    },
    skip: !token || skip,
  });

  return query;
};
