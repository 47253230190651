import {isBrowser} from "../components/_common/_constants";
import {Device} from "./analytics/types";
import {safeJsonParse} from "./safeJsonParse";

/**
 * After adding a key, be sure to update `LocalStore` and `validators`
 */
export enum LocalStorageKey {
  VisitId = "visitId",
  VisitorId = "visitorId",
  LatLong = "latLong",
  SkipFitBoundsOnLocationsRouteChange = "skipFitBoundsOnLocationsRouteChange",
  ApiTokenV2 = "apiTokenV2",
  CurrentUserId = "currentUserId",
  Device = "device",
  DynamicBannersClosed = "dynamicBannersClosed",
  IsKeyboardUser = "isKeyboardUser",
}

export type LocalStore = {
  [LocalStorageKey.VisitId]: string;
  [LocalStorageKey.VisitorId]: string;
  [LocalStorageKey.CurrentUserId]: string;
  [LocalStorageKey.SkipFitBoundsOnLocationsRouteChange]: boolean;
  [LocalStorageKey.Device]: Device;
  [LocalStorageKey.LatLong]: {
    x: number;
    y: number;
  };
  [LocalStorageKey.ApiTokenV2]: {
    [userId: string]: string;
  };
  [LocalStorageKey.DynamicBannersClosed]: string[];
  [LocalStorageKey.IsKeyboardUser]: boolean;
};

/**
 * Typesafe getter from local storage. Use in conjunction with setInLocalStorage for typesafety.
 */
export function getFromLocalStorage<T extends LocalStorageKey>(name: T): LocalStore[T] | undefined {
  return safeJsonParse(getFromLocalStorageRaw(name)) as LocalStore[T] | undefined;
}

/**
 * Typesafe setter from local storage. Use in conjunction with getFromLocalStorage for typesafety.
 */
export function setInLocalStorage<T extends LocalStorageKey>(key: T, val: LocalStore[T]): void {
  setInLocalStorageRaw(key, JSON.stringify(val));
}

/**
 * Get a raw value from local storage, or null if being called from the server.
 *
 * Callers should prefer getFromLocalStorage over this method for typesafety.
 */
export function getFromLocalStorageRaw<T extends LocalStorageKey>(
  name: T,
): string | null | undefined {
  return safeLocalStorage()?.getItem(name);
}

/**
 * Set a raw value in local storage, do nothing if being called from the server.
 *
 * Callers should prefer setInLocalStorage() over this method for typesafety.
 */
export function setInLocalStorageRaw(key: string, newValue: string) {
  const storage = safeLocalStorage();
  if (storage) {
    const oldValue = storage.getItem(key);
    if (oldValue !== newValue) {
      storage.setItem(key, newValue);

      /**
       * This is required because these events are only emitted to other
       * documents other than the ones they are created on.
       * To fix, we manually create and emit the event for the current window.
       */
      window.dispatchEvent(
        new StorageEvent("storage", {
          key: key,
          newValue,
          oldValue,
          cancelable: false,
          storageArea: storage,
        }),
      );
    }
  }
}

export function removeFromLocalStorage(key: LocalStorageKey): void {
  const storage = safeLocalStorage();
  if (storage) {
    const oldValue = storage.getItem(key);
    const newValue = undefined;
    if (oldValue !== undefined) {
      storage.removeItem(key);
      window.dispatchEvent(
        new StorageEvent("storage", {
          key: key,
          newValue,
          oldValue,
          cancelable: false,
          storageArea: storage,
        }),
      );
    }
  }
}

function safeLocalStorage(): Storage | undefined {
  if (isBrowser()) {
    try {
      return window.localStorage;
    } catch (e) {
      // Users can block access to localStorage.
      // See https://www.chromium.org/for-testers/bug-reporting-guidelines/uncaught-securityerror-failed-to-read-the-localstorage-property-from-window-access-is-denied-for-this-document
      // These shouldn't go to Sentry because they're not actionable.
      if (!(e instanceof DOMException)) {
        throw e;
      }
    }
  }
}
