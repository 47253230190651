import {IncomingMessage} from "http";

import isbot from "isbot";
import {Dispatch} from "redux";
import {AppDispatch, actions} from "src/store";

import {CarbonEnvs} from "../../constants/urls";
import {ConfigState, RegionSlug} from "../../store/types";
import {fetchLocationsAndPractices} from "../../utils/fetchLocationsAndPractices";
import {getCarbonHost} from "../../utils/urls";
import {getCookie} from "./Carbon";

const isPracticeDataSet = (config: ConfigState) =>
  Boolean(config.allSpecialties && config.locations.length && config.practices);

export const setPracticeData = async (config: ConfigState, dispatch: AppDispatch) => {
  if (!isPracticeDataSet(config)) {
    const [{practices, locations}, {default: allSpecialties}] = await Promise.all([
      fetchLocationsAndPractices(),
      import("../../../public/static/data/localSpecialties.json"),
    ]);

    dispatch(
      actions.setConfig({
        practices,
        locations,
        allSpecialties,
      }),
    );
  }
};

type SetBootStateParams = {
  dispatch: Dispatch;
  req: IncomingMessage;
  query: Record<string, unknown>;
};

export const setBootState = ({dispatch, req, query}: SetBootStateParams): void => {
  let imgExt = "webp";

  const browser = ((req || {}).headers || {})["user-agent"];

  // @ts-expect-error TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const supportsWebp = /Chrome|Opera|OPR|Firefox|Edge|Samsung/i.test(browser);
  if (!supportsWebp) imgExt = "jpg";

  const isBot = isbot(browser);

  const selectedRegion =
    (query.regionSlug as RegionSlug) || (getCookie({req, name: "selectedRegion"}) as RegionSlug);

  dispatch(
    actions.setConfig({
      imgExt,
      // @ts-expect-error TS2532, TS2345: Object is possibly 'undefined'.,  Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      host: getCarbonHost(req.headers.host) || CarbonEnvs.prod,
      pageLoaded: false,
      locationsSorted: false,
      isBot,
    }),
  );

  dispatch(actions.setUserLocation({selectedRegion}));
};
