export const SpecialtyId = {
  PRIMARY_CARE: "5814d99a-b767-4298-8d46-ada317655fda",
  URGENT_CARE: "0ad3697e-1ce1-4118-b355-d1b3dd9540ee",
  OCCUPATIONAL_HEALTH: "9672d7ec-9cc6-4d6c-ad8d-f9aa534b15a7",
  ORTHOPEDICS: "ad729f95-30ce-4beb-b262-052b6b9857a3",
  COVID_VACCINE: "fc68f27e-b693-4c89-b94c-424432234ca3",
  COVID_SEROLOGY: "e94b77cf-22dd-462b-bd68-0e6fefa03303",
  COVID_SURGERY_CLEARANCE: "3074a917-e67a-49d3-9bc3-3f9ef351d974",
  COVID_TESTING_MOBILE: "652c64cc-9fcb-442f-b71e-266390ef2f63",
  COVID_TESTING_RAPID: "3ea88aaa-94c4-4208-ad11-fcea2d231e0f",
  COVID_TRAVEL_CLEARANCE: "3ea88aaa-94c4-4208-ad11-fcea2d231e0f",
  COVID_19_DIAGNOSTIC_TESTING_SYMPTOMATIC_BOTH: "998810c7-5732-4fc2-9515-f17e39ca864c",
  COVID_19_DIAGNOSTIC_TESTING_SYMPTOMATIC_ONLY: "f2b5e873-c97b-4eb3-9d36-0788428fd672",
  COVID_19_DIAGNOSTIC_TESTING_ASYMPTOMATIC_ONLY: "ae7a194f-b90e-4f9f-bb16-a2a009fd1bd6",
  COVID_19_DIAGNOSTIC_TESTING_NEED_REFERRAL: "81819097-bb0a-4957-8a94-602234814f4a",
  COVID_19_DIAGNOSTIC_TESTING_NOT_NEED_REFERRAL: "c1a96270-ee59-4ed7-9b09-e22fb609939f",
  COVID_19_SEROLOGY_TESTING_SYMPTOMATIC_BOTH: "0a72611a-bf86-422c-9b7c-c44d7c7851b7",
  COVID_19_SEROLOGY_TESTING_SYMPTOMATIC_ONLY: "87dd835e-0814-4dc1-a54d-faede219b7d6",
  COVID_19_SEROLOGY_TESTING_ASYMPTOMATIC_ONLY: "e3482cae-cca3-4e74-bd9f-595fdf635f63",
  COVID_19_SEROLOGY_TESTING_NEED_REFERRAL: "787f6bea-1c15-4a4c-a723-b0525c14204b",
  COVID_19_SEROLOGY_TESTING_NOT_NEED_REFERRAL: "f50b8d82-8e81-49ca-94f3-af9d10af4f09",
  VIRTUAL_PRIMARY_CARE: "5aab78ac-c910-4a28-aae3-458c10ee23fd",
  VIRTUAL_URGENT_CARE: "a9073fd0-4fca-4d26-8a7a-14b5d0ee55d9",
  VIRTUAL_PROBONO_CARE: "b04b2c6d-e4b1-410a-81ae-61797e3c487f",
  VIRTUAL_PEDIATRICS: "9325b52e-3877-4b7b-b952-a6c6f5fc9c74",
  VIRTUAL_MENTAL_HEALTH: "a9b863ac-ab6f-4ee4-94d9-a98daa3e63b6",
  VIRTUAL_THERAPY: "31cf5e95-0952-4cbd-9a53-d4f74f729407",
  VIRTUAL_ORTHOPEDICS: "5202919c-6817-4763-a9f7-6956d9f8fceb",
  NORTHBAY_VIRTUAL_URGENT_CARE: "a9073fd0-4fca-4d26-8a7a-14b5d0ee55d9",
} as const;

export const virtualSpecialtyIds = [
  SpecialtyId.VIRTUAL_PRIMARY_CARE,
  SpecialtyId.VIRTUAL_URGENT_CARE,
  SpecialtyId.VIRTUAL_MENTAL_HEALTH,
  SpecialtyId.VIRTUAL_THERAPY,
  SpecialtyId.VIRTUAL_PEDIATRICS,
  SpecialtyId.VIRTUAL_PROBONO_CARE,
  SpecialtyId.VIRTUAL_ORTHOPEDICS,
];

export const covidTestingSpecialtyIds = [
  SpecialtyId.COVID_SEROLOGY,
  SpecialtyId.COVID_SURGERY_CLEARANCE,
  SpecialtyId.COVID_TESTING_MOBILE,
  SpecialtyId.COVID_TESTING_RAPID,
  SpecialtyId.COVID_TRAVEL_CLEARANCE,
  SpecialtyId.COVID_19_DIAGNOSTIC_TESTING_SYMPTOMATIC_BOTH,
  SpecialtyId.COVID_19_DIAGNOSTIC_TESTING_SYMPTOMATIC_ONLY,
  SpecialtyId.COVID_19_DIAGNOSTIC_TESTING_ASYMPTOMATIC_ONLY,
  SpecialtyId.COVID_19_DIAGNOSTIC_TESTING_NEED_REFERRAL,
  SpecialtyId.COVID_19_DIAGNOSTIC_TESTING_NOT_NEED_REFERRAL,
  SpecialtyId.COVID_19_SEROLOGY_TESTING_SYMPTOMATIC_BOTH,
  SpecialtyId.COVID_19_SEROLOGY_TESTING_SYMPTOMATIC_ONLY,
  SpecialtyId.COVID_19_SEROLOGY_TESTING_ASYMPTOMATIC_ONLY,
  SpecialtyId.COVID_19_SEROLOGY_TESTING_NEED_REFERRAL,
  SpecialtyId.COVID_19_SEROLOGY_TESTING_NOT_NEED_REFERRAL,
] as string[];

export const webSchedulingSpecialtyIds = [
  SpecialtyId.URGENT_CARE,
  SpecialtyId.COVID_TESTING_RAPID,
  SpecialtyId.COVID_TRAVEL_CLEARANCE,
  SpecialtyId.OCCUPATIONAL_HEALTH,
  SpecialtyId.PRIMARY_CARE,
] as string[];

export type SpecialtyId = (typeof SpecialtyId)[keyof typeof SpecialtyId];

export const isSpecialtyId = (str?: any): str is SpecialtyId =>
  typeof str === "string" && SpecialtyId.vals().includes(str as SpecialtyId);
