import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../../../../types/__generated__/graphql";

const defaultOptions = {} as const;
export type CurrentPatientQueryVariables = Types.Exact<{[key: string]: never}>;

export type CurrentPatientQuery = {
  __typename?: "Query";
  me?:
    | {__typename?: "AuthenticationError"}
    | {__typename?: "BillingUser"}
    | {__typename?: "OrganizationAdminUser"}
    | {
        __typename?: "PatientUser";
        defaultPatient?: {__typename?: "Patient"; id: string; firstName?: string | null} | null;
      }
    | {__typename?: "ProviderUser"}
    | null;
};

export const CurrentPatientDocument = gql`
  query CurrentPatient {
    me {
      ... on PatientUser {
        defaultPatient {
          id
          firstName
        }
      }
    }
  }
`;

/**
 * __useCurrentPatientQuery__
 *
 * To run a query within a React component, call `useCurrentPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPatientQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentPatientQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentPatientQuery, CurrentPatientQueryVariables>,
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<CurrentPatientQuery, CurrentPatientQueryVariables>(
    CurrentPatientDocument,
    options,
  );
}
export function useCurrentPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentPatientQuery, CurrentPatientQueryVariables>,
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<CurrentPatientQuery, CurrentPatientQueryVariables>(
    CurrentPatientDocument,
    options,
  );
}
export type CurrentPatientQueryHookResult = ReturnType<typeof useCurrentPatientQuery>;
export type CurrentPatientLazyQueryHookResult = ReturnType<typeof useCurrentPatientLazyQuery>;
export type CurrentPatientQueryResult = Apollo.QueryResult<
  CurrentPatientQuery,
  CurrentPatientQueryVariables
>;
