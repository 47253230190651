// Extend the Window interface
interface MyWindow extends Window {
  dataLayer: {event: string; page: string}[];
}

declare let window: MyWindow;

export const GTM_ID = "GTM-52ZKWKG";

export const pageview = (url: string) => {
  window.dataLayer.push({
    event: "pageview",
    page: url,
  });
};
