import "../../extensions";

import {colors} from "@c10h/colors";
import moment from "moment";
import {ServiceFilters, ServiceMapLocation} from "src/_services/types";

import {ApiUrl} from "../../constants/apiUrls";
import {ApptReasonId} from "../../constants/apptReasons";
import {SpecialtyId} from "../../constants/specialtyIds";
import {WorkerUrl} from "../../constants/workerUrls";
import {NextPageContextWithStore, RootStateLocation} from "../../store/types";
import {getPublicEnvVar} from "../../utils/getEnvVar";
import {getSchedulingRedirectUrl} from "../../utils/getRedirectUrl";

export const dev = !process.env.buildEnvProd && process.env.NODE_ENV !== "production";
export const buildId = process.env.BUILD_ID;
export const icomoonUrl = `https://d1azc1qln24ryf.cloudfront.net/122899/websitev5/style-cf.css?${buildId}`;

export const SITE_URL = process.env.SITE_URL;
export const IS_PROD_SITE = SITE_URL === "https://carbonhealth.com";
export const isBrowser = () => typeof window !== "undefined";
export const defaultNS = "website";
export const locizeProjectId = "b75b8ccd-4618-48b3-8447-9b1a17865232";

export const covid19Testing = "/covid-19-testing-centers";

export const s3ImagesRootUrl = "https://images.carbonhealth.com";

export const myTurnUrl = "https://myturn.ca.gov/";

// assign a random UUID and update image file name
export const insuranceIds = {
  aetnaId: "0e274a50-5150-11e7-96cb-186590d54343",
  anthemId: "0e25b97e-5150-11e7-96cb-186590d54343",
  blueShieldId: "0e265640-5150-11e7-96cb-186590d54343",
  cignaId: "0e278f24-5150-11e7-96cb-186590d54343",
  humanaId: "0e268a84-5150-11e7-96cb-186590d54343",
  healthNetId: "0e2408a4-5150-11e7-96cb-186590d54343",
  hillPhysiciansId: "6ff0864b-c954-47c8-9cad-26c7160b6098",
  medicareCaliforniaNorthId: "f64ea3bc-4b2e-4de0-9cd2-64de70e49817",
  oscarId: "d49e217f-be20-4e2e-b76e-cd5386a02a93",
  sutterHealthId: "dedc3295-8df4-4897-94db-56f741390895",
  tricareId: "0e247b7c-5150-11e7-96cb-186590d54343",
  unitedHealthCareId: "0e276d8c-5150-11e7-96cb-186590d54343",
  westernHealthAdvId: "0e2700fe-5150-11e7-96cb-186590d54343",
  medicaidOfNevadaId: "0e254b92-5150-11e7-96cb-186590d54343",
  sanaId: "1e254b92-5150-11e7-96cb-186590d54343",
};

export const v5Pages = {
  about: "/about-us",
  blog: "/blog",
  careers: "/careers",
  carbonForCampus: "/carbon-for-campus",
  confirmIdentity: "/confirm-identity",
  coronavirus: "/coronavirus",
  covid19Testing,
  covid19TestingCare: "/get-care/covid-19-testing",
  covid19TestingCenters: "/coronavirus/covid-19-testing-centers",
  covid19TravelClearance: "/get-care/covid-19-travel-testing",
  covid19Vaccination: "/get-care/covid-19-vaccination",
  covidPositiveCare: "/covid-positive-care",
  covidReady: "/covid-ready",
  covidReadyCompliance: "/covid-ready/compliance",
  covidReadyForOrgs: "/covid-ready-for-orgs",
  covidReadyQuestions: "/covid-ready-questions",
  covidReadyEducation: "/covid-ready-education",
  covidTesting: "/covid-testing",
  covidTreatment: "/get-care/covid-treatment",
  diabetesCare: "https://carbonhealth.com/primary-care/diabetes-management",
  download: "/download",
  getCareRoot: "/get-care",
  getCareSlug: "/get-care/[slug]",
  healthPass: "/healthpass",
  healthPassLP: "/health-pass",
  home: "/",
  imaging: "/get-care/imaging",
  insurance: "/insurance-pricing",
  jobs: "/jobs",
  labsAndLabResults: "/get-care/labs-and-lab-results",
  lgbtq: "/get-care/lgbtq",
  locations: "/locations",
  locations2: "/locations2",
  clinicDetails: "/locations/[slug]",
  mentalHealth: "/get-care/mental-health",
  mobileClinic: "/mobile-clinic",
  partners: "/partners",
  pediatrics: "/get-care/pediatrics",
  press: "/press",
  primaryCare: "/get-care/primary-care",
  providers: "/providers/[slug]",
  privacy: "/privacy-policy",
  privacyPractices: "/notice-of-privacy-practices",
  reason: "/reason",
  reason2: "/reason2",
  sellYourPractice: "/sell-your-practice",
  terms: "/terms-of-use",
  testpage: "/testpage",
  ucHastings: "/uc-hastings",
  urgentCare: "/get-care/urgent-care",
  virtualCare: "/virtual-care/telehealth",
  virtualCareMental: "/get-care/mental-health",
  womensHealth: "/get-care/womens-health-concern",
  workplaceHealth: "/get-care/workplace-health",
  serviceMap: "/service-map",
  collegesOfFenway: "/student-health/colleges-of-fenway",
} as const;

export type Pathname = (typeof v5Pages)[keyof typeof v5Pages];

export const apiUrlProd = ApiUrl.PROD;
const apiUrlStaging = ApiUrl.STAGING;
const apiUrlLocal = ApiUrl.LOCAL;

export const isStagingDB = process.env.DB === "stagingDB";
export const isLocalDB = process.env.DB === "localDB";

export function getApiUrl() {
  try {
    // for staging: if server, check process.env vars. if browser, check window
    if (
      (SITE_URL && SITE_URL.includes("staging")) ||
      (isBrowser() && window.location.host === "staging.carbonhealth.com") ||
      process.env.DB === "stagingDB"
    ) {
      return apiUrlStaging;
    }
    // for local: just check node env var in package.json
    if (process.env.DB === "localDB") {
      return apiUrlLocal;
    }
    return apiUrlProd;
  } catch (err) {
    return apiUrlProd;
  }
}

export function getGraphQLUrl() {
  switch (getApiUrl()) {
    case apiUrlProd:
      return ApiUrl.PROD_GRAPHQL;
    case apiUrlStaging:
      return ApiUrl.STAGING_GRAPHQL;
    case apiUrlLocal:
      return ApiUrl.LOCAL;
    default:
      return ApiUrl.PROD_GRAPHQL;
  }
}

// this should be used in server only to download data.
export function getApiUrlForScripts() {
  try {
    if (SITE_URL && SITE_URL.includes("staging")) {
      return apiUrlStaging;
    }
    if (process.env.DOWNLOAD_PRACTICE_DATA_LOCAL) {
      return apiUrlLocal;
    }
    return apiUrlProd;
  } catch (err) {
    return apiUrlProd;
  }
}

export const getLocalPractices = async () =>
  (await fetch(`${window.location.origin}/static/data/localPractices.json?${buildId}`)).json();

export const getLocalLocations = async (): Promise<RootStateLocation[]> =>
  (await fetch(`${window.location.origin}/static/data/localLocations.json?${buildId}`)).json();

export const markerClusterUrl = "/static/img/v5/maps/clusters/m";

export const supportLink = "https://support.carbonhealth.com";

export const occupationalHealthLink = "https://occupational-health.carbonhealth.com/";

export const v5PagesTitles = {
  [v5Pages.home]: "Home",
  [v5Pages.urgentCare]: "Urgent Care",
  [v5Pages.locations]: "Locations",
  [v5Pages.locations2]: "Locations",
  [v5Pages.clinicDetails]: "Location",
  [v5Pages.insurance]: "Insurance and Pricing",
  [v5Pages.about]: "About",
  [v5Pages.partners]: "Partners",
  [v5Pages.jobs]: "Jobs",
  [v5Pages.privacy]: "Privacy",
  [v5Pages.terms]: "Terms",
  [v5Pages.download]: "Download",
  [v5Pages.getCareRoot]: "Get Care",
  [v5Pages.reason]: "Reason",
  [v5Pages.coronavirus]: "Coronavirus",
  [v5Pages.covid19Testing]: "Covid Testing Centers",
  [v5Pages.virtualCare]: "Virtual Care",
  [v5Pages.virtualCareMental]: "Virtual Mental Care",
  [v5Pages.mobileClinic]: "Mobile Clinics",
  [v5Pages.covidReady]: "Covid Ready",
  [v5Pages.covidReadyForOrgs]: "Covid Ready For Organizations",
  [v5Pages.sellYourPractice]: "Sell Your Practice",
  [v5Pages.covidReadyQuestions]: "Covid Ready Questions",
  [v5Pages.covidReadyEducation]: "Covid Ready Education",
  [v5Pages.covidTesting]: "Covid Testing",
  [v5Pages.ucHastings]: "UC Law SF",
};

// adding mock function so scanner can catch
// @ts-expect-error TS7006: Parameter 'v' implicitly has an 'any' type.
export const i18n = {t: v => v};

export const mods = {
  clinic: "clinic",
  video: "video",
};

export const vcData = {
  [SpecialtyId.VIRTUAL_URGENT_CARE]: {
    color: colors.lightYellow,
    darker: colors.darkYellow,
    darker2: colors.darkerYellow,
    hero: "vcHeroUrgentCare",
    price: "$69 / appointment",
  },
  [SpecialtyId.VIRTUAL_MENTAL_HEALTH]: {
    color: colors.lightPurple,
    darker: colors.darkPurple,
    darker2: colors.darkerPurple,
    hero: "vcHeroMentalCare",
    price: "$99 / 50-min session",
  },
};

export const localhost = "http://localhost:5002";
export const prodHost = "https://carbonhealth.com";
export const host = dev ? localhost : prodHost;

export const patientAppUrl = (getPublicEnvVar("PATIENT_APP_URL") ||
  (dev ? "http://localhost:3002/patients" : "https://carbonhealth.com/patients")) as string;

export const laTimezone = "America/Los_Angeles";

export const covidUrl = "https://crbn.page.link/Od5diunth5";

export const downloadUrlDeeplink = "https://crbn.page.link/hicVl04Wj5";

export const supportEmail = "support@carbonhealth.com";
export const covidReadyUrl = "https://business.carbonhealth.com";
export const careersUrl = "https://jobs.lever.co/carbonhealth";
export const blogURL = "https://carbonhealth.com/blog";
export const twitterUrl = "https://twitter.com/carbonhealth";
export const facebookUrl = "https://www.facebook.com/CarbonHealth/";
export const linkedInUrl = "https://www.linkedin.com/company/carbon-health";
export const instagramUrl = "https://www.instagram.com/carbonhealth";

export const millisPerMinute = 1000 * 60;

export const widgetAllowedAsPaths = ["urgent-care", "mobile-clinic"];

export const sfCenterPos = {x: 37.7749, y: -122.4194};

export type VaccineSlug = "los-angeles" | "berkeley";

export const vaccineSlugs = {
  losAngeles: "los-angeles" as VaccineSlug,
  berkeley: "berkeley" as VaccineSlug,
};

export const orgSlugs = {
  RODRICK: "rodrick",
  ORDER_UP: "orderup-health",
};

export const googleStaticMapMarker =
  "https://carbonhealth.com/static/img/_common/marker-64x64.png?1";

export type ServiceGroupSlug =
  | "covid-vaccine"
  | "flu-vaccination"
  | "coronavirus-testing"
  | "mammogram"
  | "endocrinology";

export const serviceGroupSlugs = {
  COVID_VACCINE: "covid-vaccine" as ServiceGroupSlug,
  FLU_VACCINE: "flu-vaccination" as ServiceGroupSlug,
  COVID_TESTING: "coronavirus-testing" as ServiceGroupSlug,
  MAMMOGRAM: "mammogram" as ServiceGroupSlug,
  ENDOCRINOLOGY: "endocrinology" as ServiceGroupSlug,
};

export const getCarbonServiceMapSchedulingUrl = (
  serviceGroupSlug: ServiceGroupSlug,
  location: ServiceMapLocation,
  locationUrl?: ServiceFilters["locationUrl"],
): string | null => {
  const isCarbon = Boolean(location.practiceId);

  const hrefMap = {
    [serviceGroupSlugs.COVID_VACCINE]: v5Pages.covid19Vaccination,
    [serviceGroupSlugs.COVID_TESTING]: `/locations/${location.slug}`,
    [serviceGroupSlugs.FLU_VACCINE]: getSchedulingRedirectUrl({
      apptReasonId: ApptReasonId.FLU_VAX,
      practiceId: location.practiceId,
      locationId: location.id,
    }),
  };

  return isCarbon
    ? hrefMap[serviceGroupSlug] ||
        getSchedulingRedirectUrl({
          locationId: location.id,
        })
    : locationUrl || null;
};

export const c4BLinkedInConversionId = 2747490;
export const LEVER_URI = "https://api.lever.co/v0/postings/carbonhealth?mode=json";

export const forwardTo = async (
  res: NextPageContextWithStore["res"],
  url: string,
  code = 301,
): Promise<void> => {
  if (res) {
    // On the server, we'll use an HTTP response to
    // redirect with the status code of our choice.
    // 301 is for permenant redirects.
    res.writeHead(code, {Location: url});
    res.end();
  } else {
    window.location.href = url;
  }
  // While the page is loading, code execution will
  // continue, so we'll await a never-resolving
  // promise to make sure our page never
  // gets rendered.
  await new Promise(() => true);
};

export const fakeTimestamp = 1894485600000; // jan 13 2030

// if date now is jan 15, this will be march 1 10AM
export const firstDayOfTwoMonthsLater = +moment().startOf("month").add(2, "month").add(10, "hours");

export const workersDataUrls = {
  dev: `${WorkerUrl.STAGING}/webdata`,
  prod: `${WorkerUrl.PROD}/webdata`,
};

export const pricingWorkerUrls = {
  dev: `${WorkerUrl.STAGING}/pricing`,
  prod: `${WorkerUrl.PROD}/pricing`,
};

export const SMAPS_WORKER_ROOT = process.env.SMAPS_WORKER_ROOT || `${WorkerUrl.PROD}/smaps`;

export const AUTO_FILL_FORMS = process.env.NEXT_PUBLIC_AUTO_FILL_FORMS && dev;
